import React from 'react';
import useTranslations from '../useTranslations';
import Navigation from '../Navigation';
import Languages from '../Languages';
import ButtonMenu from '../ButtonMenu';
import Logo from '../Logo';

import { useMenu } from '../../hooks/menu';

import * as S from './styled';

const Header = ({lang, button}) => {
  const { home } = useTranslations(lang);
  const { openedMenu, toggleMenu, closeMenu } = useMenu();

  return (
    <S.HeaderWrapper>

      <S.Header>
        <S.Headertop>
          <S.Container>
            <ButtonMenu handleClick={toggleMenu} isActive={openedMenu} />
            <S.LogoLink to="/" lang={lang} onClick={closeMenu} title={home} aria-label={home}>
              <Logo />
            </S.LogoLink>
            <Languages lang={lang} handleClick={closeMenu} />
          </S.Container>
        </S.Headertop>
        <S.HeaderBottom>
          <S.Container>  
            <S.NavMenu className={openedMenu ? 'is-active' : ''}>
              <Navigation lang={lang} handleClick={closeMenu} button={button}/>
            </S.NavMenu>
          </S.Container>
        </S.HeaderBottom>
      </S.Header>

    </S.HeaderWrapper>
  );
};

export default Header;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as S from './styled';

const Languages = props => {
  
  const locale = props.lang;

  const { icon } = useStaticQuery(
    graphql`
    query {
      icon: allFile(filter: {name: {eq: "icon-globe"}}) {
        edges {
          node {
            publicURL
          }
        }
      }             
    }
    `,
  );  

  return (
    <S.LanguageWrapper>

      <S.LanguageItem>
        <S.LanguageIcon src={icon.edges[0].node.publicURL} alt="Languages" width="20px" height="20px" />
      </S.LanguageItem>
      <S.LanguageItem>
        <S.LanguageLink 
          to="/" 
          onClick={props.handleClick}
          className={locale === 'en' ? 'is-active' : ''}
        >
          EN
        </S.LanguageLink>
      </S.LanguageItem>
      <S.LanguageItem>
        <S.LanguageLink 
          to="/nl" 
          onClick={props.handleClick}
          className={locale === 'nl' ? 'is-active' : ''}
        >
          NL
        </S.LanguageLink>
      </S.LanguageItem>      
    </S.LanguageWrapper>
  );
};

export default Languages;

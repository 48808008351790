import React from 'react'
import useMenuFooter from '../useMenuFooter'
import { useStaticQuery, graphql } from 'gatsby'
import LogoWhite from '../LogoWhite'
import useTranslations from '../useTranslations'

import * as S from './styled'

export default function NavigationFooter ({lang}) {
    const data = useStaticQuery(graphql`
        query FooterQuery {
            allMarkdownRemark(
                filter: {
                    frontmatter: {
                        templateKey: { eq: "landing-page" }
                        navigationTitle: {}
                    }
                }
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            navigationTitle
                        }
                        fields {
                            slug
                            locale
                        }
                    }
                }
            }
        }
    `)

    const menuItems = useMenuFooter(lang)
    const { home } = useTranslations(lang)
    const landingPages = data.allMarkdownRemark.edges.filter(
        x => x.node.fields.locale === lang
    )
    return (
        <>
            <S.NavigationFooter>
                <S.NavigationFooterItems>
                    <S.NavigationFooterLink
                        to="/"
                        lang={lang}
                        aria-label="Home"
                        activeClassName="active"
                        partiallyActive={false}
                    >
                        Home
                    </S.NavigationFooterLink>

                    {menuItems.map((menu, index) => (

                        <>
                                                
                            {
                                menu.link.includes('https://') ? 
                                <S.NavigationFooterExternalLink
                                target="_blank" 
                                rel="noopener noreferrer" 
                                href={menu.link}
                                aria-label={menu.name}
                                activeClassName="active"
                                key={`${menu.link}${index}`}
                                >
                                    {menu.name}
                                </S.NavigationFooterExternalLink>  
                                
                                :

                                <S.NavigationFooterLink
                                to={menu.link}
                                lang={lang}
                                aria-label={menu.name}
                                activeClassName="active"
                                key={`${menu.link}${index}`}
                                >
                                    {menu.name}
                                </S.NavigationFooterLink>   
                            }
                                                               
                        </>

                    ))}
                </S.NavigationFooterItems>

                <S.NavigationFooterButton to="/" title={home} aria-label={home}>
                    <LogoWhite />
                </S.NavigationFooterButton>
            </S.NavigationFooter>
        </>
    )
}


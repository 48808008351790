import styled from 'styled-components';
import media from 'styled-media-query';

export const Row = styled.section`
  background-color: var(--gray-extra-light);
  background: linear-gradient(0deg, rgba(244,246,248,1) 0%, rgba(244,246,248,1) 96%, rgba(0,0,0,0.15) 100%);
`

export const TryNowWrapper = styled.div`
  text-align: center;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  margin: 3rem 0;
  padding: var(--space-xl) var(--space-lg);
  border-radius: 20px;
  border: 1px solid var(--gray-extra-light);
  background-color: var(--color-white);
`;

export const NavigationButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  padding: 0 2rem;
  height: 40px;
  border-radius: 22px;
  background: var(--color-green);
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color .3s ease-in-out;
  border: 2px solid var(--color-green);
  &:hover{
    background-color: var(--hover-color-green);
    border-color: var(--hover-color-green);
    color: var(--color-white);
  }


  ${media.greaterThan('medium')`  
    font-size: 2.2rem;
    height: 68px;
    border-radius: 36px;
    padding: 0 2.4rem;
  `}
`;
import React from 'react'

import GlobalStyles from '../styles/global'

import Header from '../components/Header'
import Footer from '../components/Footer'
import TryNow from '../components/TryNow'
import TryNowLandingPage from '../components/TryNowLandingPage'

import CookieConsent from 'react-cookie-consent'

import useTranslations from '../components/useTranslations'

import * as S from './styled'

const BaseLayout = ({ children, pageContext: { locale }, data }) => {
    const {
        cookieConsent,
        cookieButton,
        cookieReadMore,
        cookieReadMoreUrl,
    } = useTranslations(locale)

    const isLandingPage =
        data?.markdownRemark?.frontmatter?.templateKey == 'landing-page';
    const hasButton =
        (data?.markdownRemark?.frontmatter?.button?.text &&
            data?.markdownRemark?.frontmatter?.button?.url) ||
        data?.markdownRemark?.frontmatter?.button?.typeFormId;
    const hideTryNow = data?.markdownRemark?.frontmatter?.hideTryNow == true; 
      

    return (
        <>
            <GlobalStyles />
            <S.Wrapper>

            <Header 
                lang={locale}
                button={{
                    "buttonText": data?.markdownRemark?.frontmatter?.buttonText,
                    "buttonUrlInternal": data?.markdownRemark?.frontmatter?.buttonUrlInternal,
                  }}
            />
                {/* {(isLandingPage) ? (
                    <HeaderLandingPage lang={locale} headerContent={{
                      "navigationTitle": data?.markdownRemark?.frontmatter?.navigationTitle,
                      "buttonText": data?.markdownRemark?.frontmatter?.buttonText,
                      "buttonUrlInternal": data?.markdownRemark?.frontmatter?.buttonUrlInternal,
                    }} />
                ) : (
                    <Header lang={locale} />
                )} */}

                <S.SiteContent role="main">{children}</S.SiteContent>

                {hasButton || isLandingPage || hideTryNow ? null : <TryNow lang={locale} />}

                {isLandingPage ? 
                  <TryNowLandingPage lang={locale} content={{
                    "ctaTitle": data?.markdownRemark?.frontmatter?.ctaTitle,
                    "buttonText": data?.markdownRemark?.frontmatter?.buttonText,
                    "buttonUrlInternal": data?.markdownRemark?.frontmatter?.buttonUrlInternal,
                  }}/>
                : null}



                <Footer lang={locale} />

                <CookieConsent
                    location="bottom"
                    style={{
                        color: '#000',
                        backgroundColor: 'white',
                        lineHeight: '18px',
                        fontSize: '13px',
                        fontWeight: '400',
                        boxShadow: '0px 0px 15px 5px rgba(0,0,0,0.5)',
                        display: 'block',
                        textAlign: 'center',
                        padding: '0',
                    }}
                    buttonStyle={{
                        backgroundColor: '#e3df00',
                        fontWeight: '600',
                        color: '#000',
                        fontSize: '14px',
                        borderRadius: '18px',
                        height: '36px',
                        padding: '0 25px',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                    buttonText={cookieButton}
                    cookieName="gatsby-gdpr-google-tagmanager"
                >
                    {cookieConsent}{' '}
                    <a href={cookieReadMoreUrl}>{cookieReadMore}</a>
                </CookieConsent>
            </S.Wrapper>
        </>
    )
}

export { BaseLayout }

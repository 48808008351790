import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const Logo = () => {

  const { logo } = useStaticQuery(
    graphql`
    query {
      logo: allFile(filter: {name: {eq: "logo-inspect4all-default"}}) {
        edges {
          node {
            publicURL
          }
        }
      }             
    }
    `,
  );
  return <img src={logo.edges[0].node.publicURL} alt="Inspect4All logo" width="140px" height="18px"/>;
};

export default Logo;

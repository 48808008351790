import { useStaticQuery, graphql } from 'gatsby';

function useMenuFooter(lang) {
  
  const locale = lang ? lang : 'en';
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query);

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      menuItemsFooter: item.node.translations.menuItemsFooter,
    };
  });

  // Only return menu for the current locale
  const { menuItemsFooter } = simplified.filter(
    lang => lang.name === locale,
  )[0];

  return menuItemsFooter;
}

export default useMenuFooter;

const query = graphql`
  query useMenuFooter {
    rawData: allFile(filter: { sourceInstanceName: { eq: "menu" } }) {
      edges {
        node {
          name
          translations: childMenuJson {
            menuItemsFooter {
              link
              name
            }          
          }
        }
      }
    }
  }
`;

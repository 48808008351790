module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-THNQ9J4","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1040,"linkImagesToOriginal":false,"wrapperStyle":"margin-left: 0; margin-right: 0","showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"exclude":["/nl/dummy/dummy","/nl/404","/nl/404.html","/nl/cookies","/cookies"],"name":"Inspect4All","short_name":"Inspect4All","start_url":"/","background_color":"#5CCE59","theme_color":"#5CCE59","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"518ffcda99a2bd728387dff8ed613ca9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../LocalizedLink';

export const Navigation = styled.nav`
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  min-height: 80px;
  padding: 1rem 0;
  height: 100vh;
  ${media.greaterThan('medium')`
    height: auto;
    padding: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}

`;

export const NavigationSubmenu  = styled.div`
  color: var(--color-white);
  text-align: center;
  .submenu-title{
    position: relative;
  }
  ul{

  }
  li{

  }

  }
  ${media.lessThan('medium')`
    background-color: var(--secondary-color-dark);
    padding: 10px 0;
    border-radius: 5px;
    .submenu-title{
      font-size: 12px;
      padding-top: 5px;
    }
  `}

  ${media.greaterThan('medium')`
    display: inline-block;
    cursor: default;
    position: relative;
    .submenu-title{
      position: relative;
      margin-right: 40px;
      padding-right: 19px;
      &:after{
        border: 3px solid var(--color-white);
        border-radius: 2px;
        border-right: 0;
        border-top: 0;
        content: " ";
        display: block;
        height: 7px;
        width: 7px;
        pointer-events: none;
        position: absolute;
        transform: rotate(-45deg);
        transform-origin: center;
        top: 9px;
        right: 0;
      }      
    }
    ul{
      position: absolute;
      display: none;
      padding-top:24px;
      left: -40px;
      white-space: nowrap;
      text-align: left;
      li{
      padding-left: 40px;
      background-color:  var(--secondary-color-dark);
        &:first-child{
          padding-top: 20px;
        } 
        &:last-child{
          padding-bottom: 20px;
        }               
      }
    }  
    &:hover{
      ul{
        display: block;
      }  
  `}

`;

export const NavigationLink = styled(LocalizedLink)`
  color: var(--color-white);
  text-decoration: none;
  font-weight: normal;
  display: block;
  padding: 1rem 0;
  position: relative;
  text-align: center;
  ${media.greaterThan('medium')`
    font-weight: 600;
    margin-right: 40px;
    display: inline-block;
  `}

  &:after {
    ${media.greaterThan('medium')`
      content: '';
      display: inline-block;
      width: 0;
      height: 2px;
      background: var(--primary-color);
      position: absolute;
      left: 0;
      bottom: 0.75rem;
      opacity: 0;
      transition: width .3s ease-in-out;
    `}   
  }

  &:hover,
  &.active {
    font-weight: 600;
    color: var(--color-white);
    ${media.lessThan('medium')`
      text-decoration: underline;
    `}
    &:after {
      opacity: 1;
      bottom: 0.75rem;
      width: 100%;
    }
  }
`;

export const Buttons = styled.div`
  ${media.greaterThan('medium')`
    display: flex;
    align-items: center;
  `}
`;


export const LoginButton = styled.a`

${media.lessThan('medium')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 2.5rem;
  height: 44px;
  border-radius: 22px;
  background: transparent;
  border: 2px solid var(--color-white);
  color: var(--color-white);
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color .3s ease-in-out;
  &:hover{
    color: var(--primary-color);
    background-color: var(--color-white);
  } 
`}

${media.greaterThan('medium')`
  color: var(--color-white);
  text-decoration: none;
  margin: 1rem 0;
  padding: 1rem 0;
  position: relative;
  text-align: center;
  font-weight: 600;
  margin-right: 20px;
  display: inline-block;

  &:after {
    ${media.greaterThan('medium')`
      content: '';
      display: inline-block;
      width: 0;
      height: 2px;
      background: var(--primary-color);
      position: absolute;
      left: 0;
      bottom: 0.75rem;
      opacity: 0;
      transition: width .3s ease-in-out;
    `}
  }

  &:hover,
  &.active {
    font-weight: 600;
    color: var(--color-white);
    ${media.greaterThan('medium')`
      font-weight: 600;
    `}
    &:after {
      opacity: 1;
      bottom: 0.75rem;
      width: 100%;
    }
}
`}
 
`;

export const NavigationButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  height: 44px;
  border-radius: 22px;
  background: var(--color-green);
  color: var(--color-white);
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color .3s ease-in-out;
  &:hover{
    color: var(--color-white);
    background-color: var(--hover-color-green);
  }  
`;

export const NavigationButtonInternal = styled(LocalizedLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  height: 44px;
  border-radius: 22px;
  background: var(--color-green);
  color: var(--color-white);
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color .3s ease-in-out;
  &:hover{
    color: var(--color-white);
    background-color: var(--hover-color-green);
  }  
`;
import styled from 'styled-components';
import { Link } from 'gatsby';

export const LanguageWrapper = styled.ul`
  display: flex;
  align-items: center;
`;

export const LanguageIcon = styled.img`
  opacity: 0.3;
  width: 20px;
  height: 20px;
`;

export const LanguageItem = styled.li`
  border-right: 1px solid rgba(0,0,0,0.2);
  padding: 0 1rem;
  &:first-child,
  &:last-child{
    border: none;
    padding-right: 0
  }
`;

export const LanguageLink = styled(Link)`
  display: inline-block;
  color: var(--secondary-color);
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: 600;
  opacity: 0.3;
  transition: color .3s ease-in-out;
  &:hover{
    opacity: 1;
    color: var(--secondary-color);
  }  
  &.is-active {
    font-weight: 600;
    opacity: 1;
    color: var(--secondary-color);
  }
`;

import React from 'react'
import useTranslations from '../useTranslations'
import { useStaticQuery, graphql } from 'gatsby'
import NavigationFooter from '../NavigationFooter'

import * as S from './styled'

const Footer = ({ lang }) => {
    const {
        downloadApps,
        downloadAppsText,
        appStoreUrl,
        playStoreUrl,
        telephone,
        email,
        productOfMaxdoro,
    } = useTranslations(lang)

    const { logo, badgeApple, badgeGoogle } = useStaticQuery(
        graphql`
            query MyQuery {
                logo: allFile(filter: { name: { eq: "logo-maxdoro-white" } }) {
                    edges {
                        node {
                            publicURL
                        }
                    }
                }
                badgeApple: allFile(filter: { name: { eq: "badge-apple" } }) {
                    edges {
                        node {
                            publicURL
                        }
                    }
                }
                badgeGoogle: allFile(filter: { name: { eq: "badge-google" } }) {
                    edges {
                        node {
                            publicURL
                        }
                    }
                }
            }
        `
    )

    return (
        <S.FooterWrapper>
            <S.FooterTop>
                <S.FooterContainer>
                    <S.LogoLink
                        href="https://www.maxdoro.nl"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img
                            src={logo.edges[0].node.publicURL}
                            alt="Maxdoro"
                            width="140px"
                            height="48px"
                        />
                    </S.LogoLink>

                    <S.FooterColumns>
                        <S.FooterColumn>
                            <S.FooterTitle>{productOfMaxdoro}</S.FooterTitle>

                            <S.ContactColumns>
                                <S.ContactColumn>
                                    <p>
                                        Maxdoro B.V.
                                        <br />
                                        Vanadiumweg 11L
                                        <br />
                                        3812 PX Amersfoort
                                    </p>
                                </S.ContactColumn>
                                <S.ContactColumn>
                                    <p>
                                        Tel: {telephone} <br />
                                        {email}:{' '}
                                        <a href="mailto:info@maxdoro.nl">
                                            info@maxdoro.nl
                                        </a>
                                        <br />
                                        Website:{' '}
                                        <a
                                            href="https://www.maxdoro.nl"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            www.maxdoro.nl
                                        </a>
                                        <br/>
                                        KVK-nummer: 32117532
                                    </p>
                                </S.ContactColumn>
                            </S.ContactColumns>
                        </S.FooterColumn>

                        <S.FooterColumn>
                            <S.FooterTitle>{downloadApps}</S.FooterTitle>
                            <p>{downloadAppsText}</p>

                            <S.AppStores>
                                <S.LogoLink
                                    href={appStoreUrl}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img
                                        src={badgeApple.edges[0].node.publicURL}
                                        alt="Download on the App Store"
                                        width="140px"
                                        height="41px"
                                    />
                                </S.LogoLink>

                                <S.LogoLink
                                    href={playStoreUrl}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <img
                                        src={
                                            badgeGoogle.edges[0].node.publicURL
                                        }
                                        alt="Get it on Google Play"
                                        width="140px"
                                        height="41px"
                                    />
                                </S.LogoLink>
                            </S.AppStores>
                        </S.FooterColumn>
                    </S.FooterColumns>
                </S.FooterContainer>
            </S.FooterTop>

            <S.FooterBottom>
                <S.FooterContainer>
                    <NavigationFooter lang={lang} />
                </S.FooterContainer>
            </S.FooterBottom>

        </S.FooterWrapper>
    )
}

export default Footer

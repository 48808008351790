import styled from 'styled-components';
import media from 'styled-media-query';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.75);
  transition: opacity .25s ease-in-out;
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  padding: 3rem;
  opacity: 1;
  min-width: 640px;
  min-height: 640px;
  border-radius: 8px;
  background-color: var(--color-white);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.lessThan('medium')`
    padding: 1rem;
    min-height: 100%;
    min-width: 100%;
    border-radius: 0;
`}
`;

export const ModalClose = styled.a`
    position: absolute;
    color: var(--color-white);
    font-size: 24px;
    line-height: 24px;
    width: 14px;
    height: 14px;
    text-align: center;
    cursor: pointer;
    opacity: .75;
    transition: opacity .25s ease-in-out;
    text-decoration: none;
    top: -34px;
    right: 0;
    &:hover{
      color: var(--color-white);
      opacity: 1;
    }
    ${media.lessThan('medium')`
      color: #737373;
      top: 6px;
      right: 8px;
      &:hover{
        color: #737373;
        opacity: 1;
      }
    `}
`;
import styled from 'styled-components';
import media from 'styled-media-query';

export const ButtonLarge = styled.button`
  font-size: 18px;
  cursor: pointer;
  width: calc(100% - 2rem);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding: 0 2rem;
  height: 44px;
  border-radius: 22px;
  background: var(--color-green);
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color .3s ease-in-out;
  border: 2px solid var(--color-green);
  &:hover{
    background-color: var(--hover-color-green);
    border-color: var(--hover-color-green);
    color: var(--color-white);
  }


  ${media.greaterThan('medium')`  
    width: auto;
    font-size: 2.2rem;
    height: 68px;
    border-radius: 36px;
    padding: 0 2.4rem;
  `}
`;

export const Button = styled.button`
${media.lessThan('medium')`
  width: 100%;
`}
  font-size: 16px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  height: 44px;
  border-radius: 22px;
  background: var(--color-green);
  color: var(--color-white);
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color .3s ease-in-out;
  &:hover{
    color: var(--color-white);
    background-color: var(--hover-color-green);
  } 
  &.secondary{
    border: 1px solid var(--color-black);
    color: var(--color-black);
    background-color: var(--color-white);

    &:hover{
      border-color: var(--color-green);
      color: var(--color-white);
      background-color: var(--color-green);
    }      
  } 
`;

export const Title = styled.h2 `
  font-weight: 700 !important;
  line-height: 145% !important;
  font-size: 2.4rem !important;
  margin-bottom: 1rem !important;
  text-align: center !important;
  text-transform: none !important;
  ${media.greaterThan('small')`
    font-size: 2.8rem !important;
  `}  
  ${media.greaterThan('medium')`
    font-size: 3.2rem !important;
  `}    
`;

export const Text = styled.div`
  text-align: justify;
  line-height: 145% !important;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 16px 0;

`;

export const All = styled.div`
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;


export const NavigationButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 2rem;
  height: 56px;
  border-radius: 28px;
  background: #0668ed;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #0060a1;
    border-color: #0060a1;
    color: var(--color-white);
  }
`;

export const Logo = styled.img`
  align-self: center;
  height: 40px;
  }
`;

export const Image = styled.img`
  align-self: center;
  height: 80px;
  }
`;
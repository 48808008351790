import React from 'react'
import useMenu from '../useMenu'
import useTranslations from '../useTranslations'
import { useStaticQuery, graphql } from 'gatsby'
import * as S from './styled'
import TryNowButton from '../TryNowButton'

export default function Navigation(props) {
    const data = useStaticQuery(graphql`
        query HeaderQuery {
            allMarkdownRemark(
                sort: { fields: [frontmatter___navigationTitle], order: ASC }
                filter: {
                    frontmatter: {
                        templateKey: { eq: "landing-page" }
                        navigationTitle: {}
                    }
                }
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            navigationTitle
                        }
                        fields {
                            slug
                            locale
                        }
                    }
                }
            }
        }
    `)

    const button = props.button
    const lang = props.lang
    const menuItems = useMenu(lang)
    const {
        buttonLogin,
        buttonLoginUrl,
        landingsPageMenuTitle,
    } = useTranslations(lang)

    const landingPages = data.allMarkdownRemark.edges.filter(
        x => x.node.fields.locale === lang
    )

    return (
        <>
            <S.Navigation>
                <div>
                    <S.NavigationLink
                        to="/"
                        lang={props.lang}
                        aria-label="Home"
                        activeClassName="active"
                        onClick={props.handleClick}
                        partiallyActive={false}
                    >
                        Home 
                    </S.NavigationLink>

                    {landingPages.length > 1 ?
                    <S.NavigationSubmenu>
                    <div className='submenu-title'>{landingsPageMenuTitle}</div>
                    <ul>
                            {landingPages.map((lp, index) => (
                            <React.Fragment key={`${lp.node.fields.slug}${index}`}>
                            {
                            lp.node.frontmatter.navigationTitle ?
                            <li>
                                <S.NavigationLink
                                    to={'/'+lp.node.fields.slug}
                                    lang={props.lang}
                                    aria-label={lp.node.frontmatter.navigationTitle}
                                    activeClassName="active"
                                    partiallyActive={true}
                                    
                                    onClick={props.handleClick}
                                >
                                    {lp.node.frontmatter.navigationTitle}
                                </S.NavigationLink>
                            </li>
                            : null
                            }
                            </React.Fragment>
                            ))}  
                    </ul>
                </S.NavigationSubmenu>                    
                    :
                    <>
                    {landingPages.map((lp, index) => (
                            <S.NavigationLink
                                key={`${lp.node.fields.slug}${index}`}
                                to={'/'+lp.node.fields.slug}
                                lang={props.lang}
                                aria-label={lp.node.frontmatter.navigationTitle}
                                activeClassName="active"
                                partiallyActive={true}
                                
                                onClick={props.handleClick}
                            >
                                {lp.node.frontmatter.navigationTitle}
                            </S.NavigationLink>
                        ))}  </>
                    }

                    {menuItems.map((menu, index) => (
                        <S.NavigationLink
                            to={menu.link}
                            lang={props.lang}
                            aria-label={menu.name}
                            activeClassName="active"
                            partiallyActive={true}
                            key={`${menu.link}${index}`}
                            onClick={props.handleClick}
                        >
                            {menu.name}
                        </S.NavigationLink>
                    ))}
                </div>

                <S.Buttons>
                    <S.LoginButton
                        href={buttonLoginUrl}
                        target="_blank"
                        onClick={props.handleClick}
                    >
                        {buttonLogin}
                    </S.LoginButton>

                    {button.buttonText && button.buttonUrlInternal ? (
                        <S.NavigationButtonInternal
                            to={button.buttonUrlInternal}
                            lang={lang}
                        >
                            {button.buttonText}
                        </S.NavigationButtonInternal>
                    ) : (
                        <TryNowButton lang={lang} />
                    )}
                </S.Buttons>
            </S.Navigation>
        </>
    )
}

// export default Navigation

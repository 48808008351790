import React, { useEffect } from "react";
import useTranslations from "../useTranslations";
import Modal from "../Modal";
import * as S from "./styled";
import RichText from '../RichText';

import logoIncontrol from "../../images/logo-incontrol.svg";
import image from "../../images/i4a-to-incontrol.svg";
import { AlignJustify } from "styled-icons/boxicons-regular";

const TryNowButton = (props) => {
  const { lang, large, secondary } = props;
  const { register } = useTranslations(lang);
  const [modalOpen, setModalOpen] = React.useState(false);

  if (modalOpen) {
    useEffect(() => {
      document.body.style.overflow = "hidden";
    });
  } else {
    useEffect(() => {
      document.body.style.overflow = "auto";
    });
  }

  return (
    <>
      {large ? (
        <S.ButtonLarge onClick={() => setModalOpen(true)}>
          {register.button}
        </S.ButtonLarge>
      ) : (
        <S.Button
          className={secondary ? 'secondary' : null}
          onClick={() => setModalOpen(true)}>{register.button}</S.Button>
      )}
      {modalOpen ? (
        <Modal setModalOpen={setModalOpen}>
          <S.All>

          <S.Content>
            <S.Title>
              {lang === "en" ? 
                "Inspect4All becomes Incontrol" : 
                "Inspect4All wordt Incontrol"
              }
            </S.Title>

            <S.Image src={image} alt="Inspect4All → Incontrol" />
            <S.Text>
            {lang === "en" ?
              
              <RichText style={{padding: `0`, textAlign: `justify` }}>
                  <p>Dear Visitor,</p>
                  <p>Thank you very much for your interest.</p>
                  <p>As of today, Inspect4All will continue under the name Incontrol. Discover now the possibilities of Incontrol and try all forms and features for free for 14 days. Click on the button below and register now.</p>
                  <p>Have a nice day!</p>
                  <p><i>Team Incontrol</i></p>
              </RichText>
              :
              <RichText style={{padding: `0`, textAlign: `justify` }}>
                  <p>Beste bezoeker,</p>
                  <p>Hartelijk dank voor je interesse.</p>
                  <p>Vanaf heden zal Inspect4All verder gaan onder de naam Incontrol. Ontdek nu de mogelijkheden van Incontrol en probeer alle formulieren en features voor 14 dagen gratis. Klik op onderstaande button en registreer je direct.</p>
                  <p>Fijne dag!</p>
                  <p><i>Team Incontrol</i></p>
              </RichText>
            }
            </S.Text>
          </S.Content>  

            <S.Logo src={logoIncontrol} alt="Incontrol" />
          
            <S.NavigationButton href={register.url} target="_blank" rel="noopener noreferrer" >
              {register.button}
            </S.NavigationButton>

          </S.All>
        </Modal>
      ) : null}
    </>
  );
};

export default TryNowButton;
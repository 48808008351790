import React from 'react'
import * as S from './styled'

function Modal({ children, setModalOpen }) {
    return (
        <>
            <S.Modal>
                <S.ModalContent>
                    <S.ModalClose onClick={() => setModalOpen(false)}>
                        ×
                    </S.ModalClose>
                    <div style={{ minWidth: '320px' }}>
                        {children}
                    </div>
                </S.ModalContent>
            </S.Modal>
        </>
    )
}

export default Modal

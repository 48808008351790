// Only one item MUST have the "default: true" key

module.exports = {
  en: {
    default: true,    
    path: `en`,
    locale: `en-US`,
    dateFormat: `D MMMM YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Inspect4all`,
    defaultDescription: `Gatsby example site using Markdown, i18n and CMS`,
  },
  nl: {
    path: `nl`,
    locale: `nl-NL`,
    dateFormat: `D MMMM YYYY`,
    siteLanguage: `nl`,
    ogLanguage: `nl-NL`,
    defaultTitle: `Inspect4all`,
    defaultDescription: `Exemplo de Gatsby site usando Markdown, i18n e CMS`,
  },
}

import React from 'react';
import Container from '../Container';
import SubTitle from '../SubTitlePage'

import * as S from './styled';



const TryNowLandingPage = ({content, lang}) => {

  return (
    <S.Row>
    <Container>
      <S.TryNowWrapper>
        <SubTitle text={content.ctaTitle}/>
        <S.NavigationButton to={content.buttonUrlInternal} lang={lang}>
              {content.buttonText}
        </S.NavigationButton>
      </S.TryNowWrapper>
    </Container>
   </S.Row>

  );
};

export default TryNowLandingPage;
